import { Link } from "gatsby";
import React from "react";
import { Layout } from "../../components";

const examples_data = [
  {
    label: "Pobierz leady!",
    video: require("../../assets/img/crm/download-leads.mp4"),
  },
  {
    label: "Przydziel lead",
    image: require("../../assets/img/crm/assign-leads.png"),
  },
  {
    label: "Procesuj sprzedaż",
    image: require("../../assets/img/crm/sell-process.png"),
  },
  {
    label: "Zarządzaj klientami",
    image: require("../../assets/img/crm/clients-managed.png"),
  },
  {
    label: "Dodawaj zadania",
    image: require("../../assets/img/crm/add-tasks.png"),
  },
];

const Crm = ({ pageContext }) => {
  return (
    <Layout
      seo={{
        title: "adreamCRM",
        href: pageContext?.slug,
        lang: "pl",
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/ux-audit/",
      // }}
      header={{ icons: "#F95F6A" }}
    >
      <section className="crmHero">
        <h1>adreamCRM</h1>
        <img src={require("../../assets/img/crm/hero-assets.svg")} alt="" />
      </section>
      <div className="container crm_content">
        <section className="heading">
          <h2>CRM z funkcją generowania leadów</h2>
          <p>
            adreamCRM to nie kolejny system CRM. To prawdziwy lead-generator
            dedykowany agencjom digital, kreatywnym, interaktywnym oraz
            reklamowym. Co odróżnia adreamCRM od wszystkich klasycznych narzędzi
            do obsługi sprzedaży?
          </p>
        </section>
        <section>
          <h3>Nie wprowadzaj leadów, pobieraj je bezpośrednio do adreamCRM!</h3>
          <p>
            W adreamCRM pozyskujesz leady w sposób aktywny, a nie tylko
            wprowadzasz te pozyskane z innych źródeł. Oczywiście zaciągasz je
            dzięki integracji adreamCRM z bazami takimi jak; KRS, CEIDG czy GUS,
            a potem pracujesz z nimi jak w klasycznym CRM tylko wygodniej i
            lepiej, kluczowe jest jednak to, że nie czekasz na leady, a
            pobierasz je wedle kryteriów które Cię interesują!
          </p>
        </section>
        <section>
          <h3>Zdefiniuj kryteria leadów które chcesz pobrać do CRM:</h3>
          <ul>
            <li>numer pkd</li>
            <li>obecność adresu e-mail lub numeru telefonu</li>
            <li>forma prawna (np. spółka akcyjna czy spółka z o.o.)</li>
            <li>lokalizacja (województwo, miasto)</li>
            <li>wiele, wiele innych</li>
          </ul>
        </section>
        <section>
          <h3>Wygodne procesowanie sprzedaży</h3>
          <p>
            Gdy pobrałeś już leady o określonych parametrach możesz zakolejkować
            klasyczne działania sprzedażowe. Klasyczne, ale wygodniejsze i
            dopasowane do specyfiki pracy nowoczesnej agencji.
          </p>
        </section>
        <section className="crm_content__examples">
          {examples_data?.map(({ label, image, video }, index) => (
            <div key={index} className="crm_content__examples__item">
              <span>{label}</span>
              <div>
                {image ? (
                  <img src={image} alt="" />
                ) : (
                  <video muted autoPlay="autoplay" loop={true} playsInline>
                    <source src={video} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          ))}
        </section>
        <section className="crm_content__action">
          <h2>
            POBIERZ LEADY
            <br />
            już teraz!
          </h2>
          <div className="crm_content__action__wrapper">
            <img src={require("../../assets/img/crm/calendar.svg")} alt="" />
            <div className="crm_content__action__wrapper__text">
              <p>
                Pomagamy działom sprzedaży pozyskiwać precyzyjnie zdefiniowane
                leady i wygodnie procesować sprzedaż. Pełne wdrożenie z
                dostosowaniem do indywidualnych potrzeb Twojej agencji już w 14
                dni!
                <br />
                <br />
                Porozmawiajmy o zwiększeniu sprzedaży w Twojej firmie.
              </p>
              <div className="btn_wrapper">
                <Link to="/kontakt/" className="btn_custom">
                  Umów spotkanie
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Crm;
